.app {
    > * {
        > *.content {
            // background: red;
            width: 90%;
            max-width: 800px;
            margin: 0 auto;
            gap: 3rem;
            > * {
                // width: 100%;
            }
        }
    }
}

@media (max-width: 600px) {
    .app {
        > * {
            > *.content {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

}
