@import "./media";
@import "./grid";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    width: 100%;
    overflow-x: hidden;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }


  // input and textfield stylings
input, textarea {
  width: 100%;
  padding: 0.75rem 1rem;
}

@mixin textinput {
    border: 1px solid rgba(34,36, 38, 0.15);
    border-radius: $base-border-radius - 5;
    &:focus-visible {
        outline-color: $focused-clr;
    }
}
textarea {
  min-height: 150px;
  @include textinput;
}

input:not(input[type="button"]) {
  // display: none;
  @include textinput;
}













  
  *, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  

// Media Queries
*, html {
    @include xs {
      // font-size: 14px;
    }
  }
  
  *, html {
    @include sm {
      // font-size: 15px;
    }
  }
  
  *, html {
    @include md {
      // font-size: 16px;
    }
  }