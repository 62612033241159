.banner {
    position: relative;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    .content {
        position: relative;
        height: calc(100vh - 70px);
        z-index: 1;
    }

    
    & > div#b-overlay {
        height: 100%;
        width: 100%;
        background: #ccc;
        opacity: 1;
        position: absolute;
        top: 0;
        z-index: 0;

        .title {
            text-align: center;
            text-align: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, calc(-50% - 70px));
            padding: 1rem;
            white-space: nowrap;

            img {
                width: 30%;
            }

            h1 {
                background-color: transparent;
                text-transform: uppercase;
                letter-spacing: 5px;
                font-size: 64px;
            }
            p {
                font-size: 1rem;
                font-weight: 700;
                letter-spacing: 3px;
            }
        }
    }
}


@media (max-width: 600px) {
    .banner {
        .title {
            transform: translate(-50%, -50%) !important;
            h1 {
                // font-size: 2rem;
                font-size: 2rem !important;
            }
        }
    }
}