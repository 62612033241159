@import "../../styles/media";

$phone-size: 14px;

.bg-image-slider {
    position: relative;

    .img-wrapper {
        // width: 100%;
        // height: 100vh;
        // position: absolute;

        div {
            object-fit: cover;
            width: 100%;
            height: 100vh;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            transition: background-image 1s ease-in-out;
        }

        h1 {
            position: absolute;
            color: white;
            top: 50%;
            left: 10%;
            transform: translateY(-50%);

            @include m-sm {
                font-size: $phone-size;
            }
        }

    }

    .left-arrow, .right-arrow {
        cursor: pointer;
        position: absolute;
        font-size: 3rem;
        color: white;
        top: 50%;
        transition: all 0.3s;
    }

    .left-arrow {
        transform: translateY(-50%) rotate(-90deg);
        left: 4%;
        &:hover {
            transform: translateY(-50%) rotate(-90deg) scale(1.1);
        }
    }

    .right-arrow {
        right: 4%;
        transform: translateY(-50%) rotate(90deg);
        &:hover {
            transform: translateY(-50%) rotate(90deg) scale(1.1);
        }
    }

    .arrow {
        @include m-sm {
            font-size: 2rem;
        }
    }




}