.contacts {
    h1 {
        text-align: center;
        padding: 2rem 0;
        letter-spacing: 2px;
    }
    .content {
        align-items: flex-start;
        .info {
            strong {
                letter-spacing: 2px;
            }
        }
        > * {
            width: 100%;
        }
    }
}