footer {
    .go-to-top {
        color: #000000ad;
        margin: 0 auto;
        
        > * {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .arrow {
                padding-bottom: 40px;
                animation: fade-in-out 1s ease infinite alternate;
            }
            .arrow + strong {
                position: absolute;
                top: 46px;
            }
        }
    }
}