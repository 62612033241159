@import "../../../styles/animation";


.des {
    overflow: hidden;
    
    > *:not(.show) {
        opacity: 1;
        animation: fade-out 0.5s ease-in-out forwards;
    }

    > *:first-child {
        letter-spacing: 2px;
        line-height: 2;
        &.show {
            animation: slide-up 1s ease-in-out forwards;
        }
    }

    >*:nth-child(2).show {
        animation: slide-right 1s ease-in-out forwards;
    }

}
