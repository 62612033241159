
$accent-clr: #c8f469;
$white: #fff;
$primary-clr: #1E2C38;
$primary-dark: #2b2d42;
$primary-red: #ef233c;
$secondary-red: #d90429;
$bg-clr: #3D5060;
$light-bg-clr: #edf9fb;
$brown-clr: #A42040;
$action-btn-primary-clr: orange;
$action-btn-accent-clr: #FF0201;


$focused-clr: #96c8da;
$link-clr: #00acca;

$success-clr:  #6fd649;
$info-clr: #0dcaf0;
$warning-clr: #ffc107;

$icon-clr: #333;

$transition-speed: 0.3s;

$con-padding:  0 2rem;
$base-border-radius: 10px;