
@keyframes fade-in-out {
    from {
        opacity: 0.2;
        transform: translateY(20px);
    }
    to {
        opacity: 0.7;
        transform: translateY(0);
    }
}


@keyframes slide-right {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(5rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}