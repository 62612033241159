.content {
    padding: 2rem 0;
    display: flex;
    align-items: end;
    justify-content: space-between;
    .des {
        margin-bottom: 3rem;
    }
    .img-wrapper {
        position: relative;
        .v-overlay {
            position: absolute;
            left: 0; 
            right: 0;
            bottom: 0;
            top: 0;
        }
    }
}