span {
    cursor: pointer;
    // position: absolute;
    font-size: 3rem;
    color: white;
    transition: all 0.3s;
    .left {
        transform: rotate(-90deg);
    }
    .right {
        transform: rotate(90deg);
    }
    .down {
        transform: rotate(180deg);
    }
}

